// React Basic and Bootstrap
import React, { useEffect } from 'react';
import Section from './Section';
import Products from './Products';

const IndexMain = () => {
  
    const scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
            } else {
                navBar.classList.remove("nav-sticky");
                document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
                document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
                document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
            }
        }
    }

    useEffect(() => {
        document.body.classList = "";
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        window.addEventListener("scroll", scrollNavigation, true);

        // Cleanup by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    return (
        <React.Fragment>
            <Section />
            <Products />
        </React.Fragment>
    );
}

export default IndexMain;
