import React from 'react';

const GithubLoginComponent = () => {
  const handleLogin = () => {
    window.location.href = 'http://localhost:5000/v1/account/github-login';
  };

  return (
    <button onClick={handleLogin}>Login with GitHub</button>
  );
};

export default GithubLoginComponent;
