import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import contact from "../../assets/images/contact.svg";
import Cookies from 'js-cookie';

const LoginModal = ({ isOpen, toggle }) => {

    const handleGoogleSignIn = () => {
        const clientId = '385084603301-21o6hi29jbrjabdi4ha396evaflf8dn1.apps.googleusercontent.com';
        const redirectUri = 'https://console.floom.ai';
        const scope = 'openid email profile';
        const state = Math.random().toString(36).substring(7); // Example of generating a state value
        Cookies.set('oauth_state', state, { domain: '.floom.ai', path: '/', secure: true });
        Cookies.set('oauth_provider', 'google', { domain: '.floom.ai', path: '/', secure: true });
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}`;
        window.location.href = googleAuthUrl;
    };
    
    const handleGithubSignIn = () => {
        const clientId = 'Ov23liQl9jLCOjqA4faD';
        const redirectUri = 'https://console.floom.ai';
        const scope = 'read:user user:email';
        const state = Math.random().toString(36).substring(7); // Example of generating a state value
        Cookies.set('oauth_state', state, { domain: '.floom.ai', path: '/', secure: true });
        Cookies.set('oauth_provider', 'github', { domain: '.floom.ai', path: '/', secure: true });
        const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${state}`;
        window.location.href = githubAuthUrl;
    };

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            toggle={toggle}
            id="login-popup"
            tabIndex="-1"
            centered
        >
            <ModalBody className="modal-lg p-0">
                <Container fluid className="px-0">
                    <Row className="align-items-center g-0">
                        <Col lg={6} md={5}>
                            <img src={contact} className="img-fluid" alt="Sign In" />
                        </Col>
                        <Col lg={6} md={7}>
                            <div className="p-4">
                                <h4 className="mb-4 text-center">Sign In</h4>
                                <div className="d-grid gap-3 mb-4">
                                    <Button color="danger" onClick={handleGoogleSignIn} className="d-flex align-items-center justify-content-center">
                                        <FeatherIcon icon="google" className="fea icon-sm me-2" />
                                        Sign in with Google
                                    </Button>
                                    <Button color="dark" onClick={handleGithubSignIn} className="d-flex align-items-center justify-content-center">
                                        <FeatherIcon icon="github" className="fea icon-sm me-2" />
                                        Sign in with GitHub
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    );
};

export default LoginModal;
