import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Images
import marketplace01 from "../../assets/images/nft/1.jpg";
import marketplace02 from "../../assets/images/nft/2.jpg";
import marketplace03 from "../../assets/images/nft/3.jpg";
import marketplace04 from "../../assets/images/nft/4.jpg";
import marketplace05 from "../../assets/images/nft/5.jpg";
import marketplace06 from "../../assets/images/nft/6.jpg";
import client01 from "../../assets/images/client/01.jpg";
import client02 from "../../assets/images/client/02.jpg";
import client04 from "../../assets/images/client/04.jpg";

const Products = () => {
    const productsData = [
        {
            id: 1,
            cardImg: marketplace01,
            badge: 231,
            title: "chat-pdf-pro",
            description: "Create a chatbot with PDF files (hosted on AWS S3), concise, fast and approriate...",
            img: client01,
            author: "Kumar Lichi",
            version: "v1.12",
            rating: 4,
            downloads: 152,
        },
        {
            id: 2,
            cardImg: marketplace02,
            badge: 232,
            title: "extract-image-objects",
            description: "Extract objects from any given image, return in JSON array format",
            img: client02,
            author: "Lana GenAI",
            version: "v1.93",
            rating: 5,
            downloads: 232,
        },
        {
            id: 3,
            cardImg: marketplace03,
            badge: 232,
            title: "translate-code-turbo",
            description: "Translate any code to any code, includes full validation, high speed",
            img: client02,
            author: "Lana GenAI",
            version: "v0.58",
            rating: 3,
            downloads: 716,
        },

        {
            id: 4,
            cardImg: marketplace04,
            badge: 232,
            title: "extract-text-emotions",
            description: "analyzes text and returns an array of detected emotions, emotional tone and sentiment expressed in the content",
            img: client04,
            author: "AIMaster91",
            version: "v0.83",
            rating: 4,
            downloads: 551,
        },

        {
            id: 3,
            cardImg: marketplace05,
            badge: 232,
            title: "extract-physical-addresses",
            description: "scans text and returns an array of physical addresses, extracting detailed location information from the provided content",
            img: client04,
            author: "AIMaster91",
            version: "v1.21",
            rating: 4,
            downloads: 185,
        },

        {
            id: 4,
            cardImg: marketplace06,
            badge: 232,
            title: "text-to-sql",
            description: "Converts natural language text into SQL queries, enabling users to interact with databases using plain language",
            img: client04,
            author: "AIMaster91",
            version: "v2.10",
            rating: 3,
            downloads: 441,
        },
        // ...other products
    ];

    return (
      <React.Fragment>
          <Container style={{ marginBottom: '100px', marginTop: '100px' }}>
              <Row className="justify-content-center">
                  <Col className="col-12">
                      <div className="section-title mb-4 pb-2 text-center">
                          <h4 className="title mb-4">TOP AI FUNCTIONS</h4>
                          <p className="text-muted para-desc mb-0 mx-auto">Fully portable & free <span className="text-primary fw-bold">AI Functions</span>, developed by our community.</p>
                      </div>
                  </Col>
              </Row>

              <Row>
                  {productsData.map((item, key) => (
                    <Col key={key} lg={6} md={6} sm={12} className="col-12 mt-4 pt-2">
                        <Card className="marketplace-item rounded shadow overflow-hidden">
                            <CardBody className="p-3">
                                <div className="d-flex align-items-center">
                                    <img src={item.cardImg} className="img-fluid item-img rounded-circle me-3" alt="" style={{ width: '50px', height: '50px' }} />
                                    <div>
                                        <h5 className="title text-dark">{item.title}</h5>
                                        <p className="text-muted mb-0">{item.description}</p>
                                    </div>
                                </div>
                                <div className="bid-btn p-3 text-center">
                                    <Link className="btn btn-primary me-2" to="/function">Deploy Now</Link>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div className="d-flex align-items-center">
                                        {[...Array(5)].map((star, i) => (
                                          <i key={i} className={`mdi mdi-star${i < item.rating ? '' : '-outline'} text-warning`}></i>
                                        ))}
                                    </div>
                                    <span className="badge rounded-md bg-light shadow">{item.downloads}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div className="d-flex align-items-center">
                                        <img src={item.img} className="avatar avatar-sm rounded-circle me-2" alt="" />
                                        <span>{item.author}</span>
                                    </div>
                                    <span className="text-muted">{item.version}</span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                  ))}
              </Row>
          </Container>
      </React.Fragment>
    );
}

export default Products;
