import React from 'react';
import axios from 'axios';

const GoogleLoginComponent = () => {
  const handleLogin = () => {
    window.location.href = 'http://localhost:5000/v1/account/google-login';
  };

  return (
    <button onClick={handleLogin}>Login with Google</button>
  );
};

export default GoogleLoginComponent;
