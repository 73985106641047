import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Products from './Products';
import { Container, Row, Col, Badge, Card, Input, Alert } from "reactstrap";
import "../../assets/css/marketplace.css";

//import Images
import bgimg from "../../assets/images/floom-ai-integration-dude-surfing.jpg";

//React Typist
import Typist from 'react-typist';

//Import Images
// import home from "../../../assets/images/job/home.png";

const Marketplace = () => {
        return (
            <React.Fragment>
                <div className="title-heading text-center mt-4 mt-md-5 pt-md-5">

                    <TypingComponent />

                    <p className="para-desc mx-auto text-muted">Huge community of AI functions ready for your project.</p>

                    <SearchBar />
                </div>
              <Products />
          </React.Fragment>
            );
    };

export default Marketplace;

const SearchBar = () => (
    <div className="search-container">
        <Card className="rounded mt-4 search-card">
            <Row className="align-items-center">
                <Col>
                    <Input type="text" className="form-control" required placeholder="Search for AI Function" />
                </Col>
                <Col>
                <div className="d-grid">
                    <input type="submit" id="search" name="search" className="searchbtn btn btn-primary" value="Search" />
                </div>
                </Col>
            </Row>
        </Card>
    </div>
);


const TypingComponent = () => {
    const [typing, setTyping] = useState(true);

    useEffect(() => {
        if (!typing) {
            setTyping(true);
        }
    }, [typing]);

    return (
        <h1 className="heading mb-3">
            AI Functions for{" "}
            {typing ? (
                <Typist onTypingDone={() => setTyping(false)}>
                    <span className="element text-primary">Developers</span>
                    <Typist.Backspace count={20} delay={400} />
                    <span className="element text-primary">Apps</span>
                    <Typist.Backspace count={15} delay={400} />
                    <span className="element text-primary">Companies</span>
                    <Typist.Backspace count={15} delay={400} />
                </Typist>
            ) : (
                <span></span>
            )}
        </h1>
    );
};
