import React, { useState } from 'react';
import { useAuth } from '../../pages/Home/auth/AuthContext';

const LoginStateContainer = () => {
    const { isLoggedIn, handleLogout } = useAuth();
    const [loginModal, setLoginModal] = useState(false);

    const toggleLoginModal = () => {
        setLoginModal(!loginModal);
    };

    const handleConsoleClick = () => {
        window.location.href = 'https://console.floom.ai';
    }

    return (
        <>
            <button
                style={{ margin: '10px' }}
                onClick={handleConsoleClick}
                className="btn btn-primary me-2"
                >
                    Console
                </button>

            {/* {isLoggedIn ? (
                <button onClick={handleLogout} className="btn btn-primary me-2">Logout</button>
            ) : (
                <button
                    style={{ margin: '10px' }}
                    onClick={toggleLoginModal}
                    className="btn btn-primary me-2"
                >
                    Console
                </button>
            )} */}

            {/* <LoginModal isOpen={loginModal} toggle={toggleLoginModal} /> */}
        </>
    );
};

export default LoginStateContainer;
