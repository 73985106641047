// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for authorization code and state in URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get('code');
    const state = queryParams.get('state'); // Get the state from the query parameters
    
    if (authCode) {
      const storedState = Cookies.get('oauth_state'); // Retrieve the stored state
      const provider = Cookies.get('oauth_provider'); // Retrieve the stored provider
        
      if (state === storedState) {
        sendAuthCodeToBackend(authCode, provider, storedState)
          .then(data => {
            if (data.sessionToken) {
              Cookies.set('SessionToken', data.sessionToken, { domain: '.floom.ai', sameSite: 'None', secure: true });
              setIsLoggedIn(true);
              navigate('/', { replace: true });
            }
          })
          .catch(error => {
          });
      } else {

      }
    } else {
      const existingSessionToken = Cookies.get('SessionToken');
      setIsLoggedIn(!!existingSessionToken);
    }
  }, [location.search, navigate]);
  
  const sendAuthCodeToBackend = async (authCode, provider, state) => {
    
    const endpoint = provider === 'google' ? 'google-login' : 'github-login';
    const response = await fetch(`https://api.floom.ai/v1/account/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: authCode, state: state }),
    });
    return response.json();
  };
  
    
  const handleLogout = () => {
      const sessionToken = Cookies.get('SessionToken');
      
      axios.get('https://api.floom.ai/v1/account/logout', {
          headers: { 'Api-Key': sessionToken }
      })
      .then(response => {
          Cookies.remove('SessionToken');
          setIsLoggedIn(false);
      })
      .catch(error => {
          console.error('Error logging out:', error);
      });
   };

  return (
    <AuthContext.Provider value={{ isLoggedIn, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);