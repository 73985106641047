import React, { Component, Suspense } from "react";
import BackToTop from "./backToTop";
import withRouter from "../../common/data/withRouter";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));

const Footer = React.lazy(() => import("./Footer"));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <Topbar hasDarkTopBar={this.props.hasDarkTopBar} />

          {this.props.children}
          
          <Footer />


          {/* scrollup button */}
          {/* <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36, zIndex: 9999, }}
            AnimationDuration={50}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton> */}
          <BackToTop />
        </Suspense>

      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
