import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//import Images
import bgimg from "../../assets/images/floom-ai-integration-dude-surfing.jpg";

const Section = () => {
    return (
      <React.Fragment>
        <section className="bg-half bg-light d-table w-100">
          <Container>
            <Row className="align-items-center">
              <Col md={6} xs={12}>
                <div className="title-heading">
                  <span className="badge rounded-pill bg-soft-primary">
                    Open source
                  </span>
                  <h1 className="heading-large">
                    AI Marketplace for Apps
                  </h1>
                  <p className="para-desc text-muted">
                  Add <strong>AI Functions</strong> to your apps in 🕒 5 min, without any AI knowledge.
                  </p>
                  <div className="mt-4 pt-2">
                    <Link to="/marketplace" className="btn btn-primary me-2">
                      AI Marketplace
                    </Link>{" "}
                    <Link
                      to="https://floomai.github.io/"
                      className="btn btn-outline-primary"
                    >
                      📚 Documentation
                    </Link>
                  </div>
                  <p className="text-muted mb-0 mt-3">Version: v1.3.0</p>
                </div>
              </Col>

              <Col md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src={bgimg} className="img-fluid d-block mx-auto" alt="" />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
};
export default Section;
